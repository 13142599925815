export const environment = {
  production: true,
  staging: false,
  apiUrlRobby: 'https://hermes-files.pulzo2.com/documents',
  apiUrl: 'https://hermes-backend.pulzo2.com',
  pageSize: 25,
  release: '5.5.6',
  sentry: 'https://5c9d7582d3f54ebba23055f17d8c2e03@o1114920.ingest.sentry.io/6293326',
};

// pendiente actualizar apiUrlRobby
